import styled from "styled-components/native";
import { TextWrapper } from "../../Atoms/Text";
import { Layout } from "../../Components/Layout";
import { PRIVACY, TERMS } from "./data";

const Title = styled(TextWrapper)`
  color: ${(props) => props.theme.appColor};
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 1.2px;
  margin-top: 5%;
`;

const Text = styled(TextWrapper)`
  margin: 2% 0%;
`;

const OrangeText = styled(TextWrapper)`
  color: ${(props: any) => props.theme.appColor2};
  font-weight: 700;
`;

const View = styled.View``;
const BulletPoint = styled.View`
  background-color: ${(props: any) => props.theme.appColor2};
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-right: 2%;
`;
const BulletText = styled(Text)`
  margin: 0.7% 0%;
  margin-left: 5%;
`;

const Bullet = (props) => {
  return (
    <BulletText>
      <BulletPoint />
      {props.text}
    </BulletText>
  );
};

export const TermsScreen = () => {
  return (
    <Layout>
      <View style={{ paddingHorizontal: "5%", backgroundColor: "white" }}>
        {TERMS.map((pp, index) => {
          return (
            <>
              <Title key={`INDEX_${index}`}>{pp.title.text}</Title>
              {pp.contents.map((cont) => {
                if (cont.sub && cont.text) {
                  return (
                    <Text>
                      <OrangeText>{cont.sub}</OrangeText>
                      {cont.text}
                    </Text>
                  );
                }

                if (cont.text && !cont.sub) {
                  return <Text>{cont.text}</Text>;
                }

                if (cont.bullets) {
                  return cont.bullets.map((bu: any) => (
                    <Bullet key={bu.text} text={bu.text} />
                  ));
                }
                return null;
              })}
            </>
          );
        })}
      </View>
    </Layout>
  );
};
