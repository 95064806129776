import { useNavigation } from "@react-navigation/native";
import { useState } from "react";
import { TouchableOpacity } from "react-native";
import styled from "styled-components/native";
import { ButtonWrapper } from "../../Atoms/Button";
import { TextWrapper } from "../../Atoms/Text";
import { googleLocationApi } from "./api";
const SEARCH_IMAGE = require("../../images/search_icon.png");

const Row = styled.View`
  flex-direction: row;
  align-items: center;
  gap: 8px;
  height: 56px;
  position: relative;
`;

const NewButton = styled(ButtonWrapper)`
  height: 100%;
  align-items: center;
  justify-content: center;
`;
const NewButtonText = styled(TextWrapper)`
  font-size: 16px;
  color: white;
`;

const TextInputWrapper = styled.View`
  flex: 1;
  background-color: #8885;
  border-radius: 4px;
  flex-direction: row;
  align-items: center;
`;

const Input = styled.TextInput`
  color: white;
  width: 100%;
  height: 56px;
  outline:none;
  border:none;
  font-size: 18px;
  &:placeholder-color: white;
`;

const SearchImage = styled.Image`
  height: 24px;
  width: 24px;
  margin: 0px 12px;
`;

const LocationResultWrapper = styled.View`
    top: -3%;
    width: 100%;
    border-radius: 8px
    background-color: #fff9;
    z-index: 101;
`;

const Wrapper = styled.View`
  position: relative;
  transition: all 0.5s ease-out;
`;

const List = styled.TouchableOpacity`
  padding: 12px;
  border-bottom-color: #fff9;
  border-bottom-width: 1px;
`;

const ListTitle = styled.Text`
  font-size: 18px;
  font-weight: 600;
`;

const ListSub = styled.Text`
  font-size: 16px;
  font-weight: 400;
  color: #444;
`;

const SearchButton = (props: any) => {
  return (
    <TouchableOpacity
      style={{ height: "100%" }}
      activeOpacity={0.7}
      onPress={props.onClick}
    >
      <NewButton>
        <NewButtonText>Search</NewButtonText>
      </NewButton>
    </TouchableOpacity>
  );
};

const LocationList = (props: any) => {
  const { navigate } = useNavigation();
  if (props.locations.length > 0) {
    return (
      <LocationResultWrapper>
        {props.locations.map((location: any) => (
          <List
            key={location.place_id}
            onPress={() =>
              navigate("Explore", {
                location: [location.main, location.sub].join(", "),
              })
            }
          >
            <ListTitle>{location.main}</ListTitle>
            <ListSub>{location.sub}</ListSub>
          </List>
        ))}
      </LocationResultWrapper>
    );
  }

  return null;
};

export const Form = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [locations, setLocations] = useState([]);
  return (
    <Wrapper>
      <Row style={{ marginVertical: "5%" }}>
        <TextInputWrapper>
          <SearchImage source={SEARCH_IMAGE} resizeMode="contain" />
          <Input
            placeholder="Type your location"
            onChangeText={(text) => setSearchTerm(text)}
          />
        </TextInputWrapper>
        <SearchButton
          onClick={() => googleLocationApi(searchTerm).then(setLocations)}
        />
      </Row>
      <LocationList locations={locations} />
    </Wrapper>
  );
};
