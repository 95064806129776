import styled from "styled-components/native";
const HeroImage = require("../../images/landing_home_burger.png");
const Wrapper: any = styled.ImageBackground`
  height: ${(props: any) => props.height || "60vh"};
  position: relative;
`;

export const Hero = (props: any) => {
  return (
    <Wrapper
      height={props.height}
      resizeMode="cover"
      source={props.image ? props.image : HeroImage}
    >
      {props.children}
    </Wrapper>
  );
};
