export const PRIVACY = [
  {
    title: {
      text: "Welcome to Steedit.!",
      style: {},
    },
    contents: [
      {
        text: "Steedit. is owned and operated by Steedit., LLC (Limited Liability Company).",
      },
      {
        text: "Steedit. values your privacy and the protection of your personal data. This privacy policy describes what information we collect from you, how we collect it, how we use it, how we obtain your consent, how long we keep it in our databases and, if necessary, with whom we share it.",
      },
      {
        text: `By using the platform, you are accepting the practices described in this Privacy Policy. Use of the platform is also subject to our Terms and Conditions. This Privacy Policy may change from time to time. Your continued use of the platform after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates. This Privacy Policy has been developed and is maintained in accordance with all applicable federal and international laws and regulations and specifically with the California Online Privacy Protection Act (CalOPPA – U.S regulation).`,
      },
    ],
  },
  {
    title: {
      text: "General Information",
      style: {},
    },
    contents: [
      {
        text: "The personal data of the users that are collected and processed through:",
      },
      {
        bullets: [
          {
            text: "The Steedit. App (iOS version – available on App store)",
            type: "text",
          },
          { text: "https://Steedit.com", type: "link" },
        ],
      },
      {
        text: "Will be under responsibility and in charge of:",
      },
      {
        bullets: [
          {
            text: "Steedit., LLC., (700 S. Flower St, Suite 1000 Los Angeles CA 90017",
            type: "text",
          },
        ],
      },
    ],
  },
  {
    title: {
      text: "Types of Information Gathered",
      style: {},
    },
    contents: [
      {
        text: "The information we collect from our users helps to personalize and continually improve the user experience on the platform. Here are the types of information we gather:",
      },
      {
        sub: "Information You Give Us:",
        text: " You provide information when you search, read, listen, and view content, download and use the application, register as a user, use the services and/or communicate with us through our contact information or through our contact forms. As a result of those actions, you might supply us with the following information:",
      },
      {
        bullets: [
          { text: "First and last name", type: "text" },
          { text: "Date of birth", type: "text" },
          { text: "Gender", type: "text" },
          { text: "Email address", type: "text" },
          { text: "Phone number (includes WhatsApp number)", type: "text" },
          { text: "Address", type: "text" },
          { text: "Zip/postal code", type: "text" },
          { text: "Payment information", type: "text" },
          { text: "Location information", type: "text" },
          { text: "User content", type: "text" },
          { text: "IP address", type: "text" },
          {
            text: "Any additional information relating to you that you provide to us directly through our platform or indirectly through our platform or online presence such as ‘cookies’.",
            type: "text",
          },
        ],
      },
      {
        text: "Steedit. will not collect any personally identifiable information about you unless you provide it.",
      },
      {
        sub: "Information Collected Automatically.",
        text: " By accessing, registering, and using the platform you automatically provide us with the following information:",
      },
      {
        bullets: [
          {
            text: "The device and usage information you use to access the website.",
            type: "text",
          },
          { text: "Your IP addresses", type: "text" },
          { text: "Browser and device characteristics", type: "text" },
          { text: "Operating system", type: "text" },
          { text: "Referring URLs", type: "text" },
          { text: "Your location", type: "text" },
          {
            text: "What parts of the website you use and how often",
            type: "text",
          },
        ],
      },
      {
        text: "If you access the website through a mobile phone, we will collect the following information:",
      },
      {
        bullets: [
          { text: "Mobile device ID", type: "text" },
          { text: "Model and manufacturer", type: "text" },
          { text: "Operating system", type: "text" },
          { text: "Version information", type: "text" },
          { text: "IP address", type: "text" },
        ],
      },
      {
        sub: "Payment information.",
        text: `Your credit/debit card information or payment information will be processed by “Stripe” (payment platform available on the platform), which will treat and safeguard your data with total security and with the exclusive purpose of processing the purchase of the services. Steedit. reserves the right to contract any payment platform available in the market, which treats your data for the exclusive purpose of processing the purchase of the services.`,
      },
      {
        text: `Please consult the “Stripe” Privacy Policy: https://stripe.com/en-gb-us/privacy.`,
      },
      {
        sub: "GOOGLE Analytics.",
        text: ` We use Google Analytics provided by Google, Inc., USA (“Google”). These tool and technologies collect and analyze certain types of information, including IP addresses, device and software identifiers, referring and exit URLs, feature use metrics and statistics, media access control address (MAC Address), mobile unique device identifiers, and other similar information via the use of cookies. The information generated by Google Analytics (including your IP address) may be transmitted to and stored by Google on servers in the United States. We use the GOOGLE Analytics collection of data to enhance the platform and improve our service.`,
      },
      {
        text: "Please consult Google's privacy policy: https://policies.google.com/privacy.",
      },
      {
        sub: "Facebook Pixel.",
        text: "Our platform uses Facebook pixels that enable us, our advertisers and service providers:",
      },
      {
        bullets: [
          {
            text: "Collect statistics on our platforms and social networks (for example, number of users who visited a page).",
            type: "text",
          },
          {
            text: "Collect information about how you interact with our platforms and social networks (e.g., whether you opened or followed links contained within).",
            type: "text",
          },
          {
            text: "Personalize online services and marketing communications.",
            type: "text",
          },
          {
            text: "Match ads to users and optimize advertising campaigns.",
            type: "text",
          },
        ],
      },
      {
        text: "Please consult Facebook's privacy policy: https://www.facebook.com/privacy/explanation.",
      },
      {
        sub: "Location.",
        text: ` We store real-time information about the location of users registered in the application when using the services available on the platform, accessing the GPS function built into the mobile devices of each user. This information is stored to provide the correct use of the application and provide specific services according to the user's location. This information is used for the time necessary to provide the services and is stored in the history of each user. Users will be able to consult their order history through the platform. However, this information does not identify or individualize the person, it is completely anonymous. The user can request the removal of such information, but certain functionalities of the application would not be available. Location information collected through the platform will be treated in accordance with this privacy policy.`,
      },
      {
        sub: "Automatic Information.",
        text: ` Like many platforms, we use "cookies." We obtain certain types of anonymous information which is not personally identifiable when your web browser accesses Steedit. or third-party content served by or on behalf of Steedit. on other platforms. Some of the information we collect and analyze includes the Internet protocol (IP) address; computer and connection information such as browser type, version, and connection speed; purchase history; and the products you searched for, viewed, and purchased.`,
      },
      {
        sub: "Social Media.",
        text: " On our platform you will find links and functions linked to different social media, in which you can share your information.",
      },
      {
        text: "It is advisable to consult the privacy policy and data protection of each social media used on our platform.",
      },
      {
        bullets: [
          {
            text: "Facebook: https://www.facebook.com/privacy/explanation",
            type: "text",
          },
          {
            text: "Instagram: http://instagram.com/about/legal/privacy/",
            type: "text",
          },
          { text: "Twitter: https://twitter.com/privacy", type: "text" },
        ],
      },
    ],
  },
  {
    title: { text: "How Long We Keep Your Data" },
    contents: [
      {
        text: `Personal data will be kept for the time necessary for the provision of the platform and services. Steedit. may be allowed to retain personal data for a longer period whenever the user has given consent to such processing, as long as such consent is not withdrawn. Furthermore, Steedit. may be obliged to retain personal data for a longer period whenever required to do so for the performance of a legal obligation or upon order of an authority. Once the retention period expires, personal data shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification and the right to data portability cannot be enforced after expiration of the retention period.`,
      },
    ],
  },
  {
    title: { text: "How We Use Your Information" },
    contents: [
      {
        text: `In general, we use the information we collect primarily to provide, maintain, protect and improve our current platform and services. We use personal information collected through our site as described below and described elsewhere in this Policy to:`,
      },
      {
        bullets: [
          { text: `Identify you as a user in our system.`, type: "text" },
          { text: `User registration.`, type: "text" },
          {
            text: `Provide the applications (iOS version as now).`,
            type: "text",
          },
          { text: `Place orders and deliver orders.`, type: "text" },
          {
            text: `Perform searches according to user's location.`,
            type: "text",
          },
          {
            text: `Display search results according to the user's criteria and location.`,
            type: "text",
          },
          { text: `Process payments.`, type: "text" },
          { text: `Provide the services.`, type: "text" },
          { text: `Facilitate communication between users.`, type: "text" },
          {
            text: `Understand and enhance your experience using our services.`,
            type: "text",
          },
          {
            text: `Respond to your comments or questions through our support team.`,
            type: "text",
          },
          {
            text: `Send you related information, including confirmations, invoices, technical notices, updates, security alerts and support and administrative messages.`,
            type: "text",
          },
          {
            text: `Communicate with you about upcoming events, offers and news about content and services offered by Steedit. and our selected partners.`,
            type: "text",
          },
          { text: `Marketing purposes of Steedit.`, type: "text" },
          {
            text: `Link or combine your information with other information we get from third parties to help understand your needs and provide you with better service.`,
            type: "text",
          },
          {
            text: `Protect, investigate and deter against fraudulent, unauthorized or illegal activity.`,
            type: "text",
          },
        ],
      },
    ],
  },
  {
    title: { text: "How Do You Get My Consent?" },
    contents: [
      {
        text: `By registering as a user, downloading the application, using the services, communicating with us through the contact forms or our contact information, accepting the use of cookies by our website and providing us with personal information to communicate with you, you consent to our collection, storage and use of your information on the terms contained in this privacy policy. You consent to the use of cookies on our platform when you give your acceptance through the pop-up window shown on the home page when you enter the platform. You may withdraw your consent by sending us your request via the contact information or the contact page.`,
      },
    ],
  },
  {
    title: { text: `How We Share Information` },
    contents: [
      {
        text: "Information about our customers is an important part of our business, and we are not in the business of selling it to others. We share customer information only as described below.",
      },
      {
        sub: "Third-Party Service Providers.",
        text: ` We use the services of third parties to perform certain functions on the platform. For example, create the platform and host the website, send email, analyze data (Google Analytics), create ads (Facebook, Google), provide marketing services and provide search results.`,
      },
      {
        text: `These third-party services and tools may have access to personal information needed to perform their functions but may not use that information for other purposes. Information shared with these third-party services will be treated and stored in accordance with their respective privacy policies and our privacy policy.`,
      },
      {
        sub: `Business Transfers.`,
        text: ` In the event that Steedit. creates, merges with, or is acquired by another entity, your information will most likely be transferred. Steedit. will email you or place a prominent notice on our Platform before your information becomes subject to another privacy policy.`,
      },
      {
        sub: `Protection of Steedit.`,
        text: ` and others. We release personal information when we believe release is appropriate to comply with the law, enforce or apply our Terms and conditions and other agreements, or protect the rights, property, or safety of Steedit., our users or others. This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction.`,
      },
      {
        sub: `With Your Consent.`,
        text: ` Other than as set out above, you will receive notice when personally identifiable information about you might go to third parties, and you will have an opportunity to choose not to share the information.`,
      },
      {
        sub: `Anonymous Information.`,
        text: ` Steedit. uses the anonymous browsing information collected automatically by our servers primarily to help us administer and improve the Platform. We may also use aggregated anonymous information to provide information about the Platform to potential business partners and other unaffiliated entities. This information is not personally identifiable.`,
      },
      {
        sub: `Email Address.`,
        text: ` The email address that you supply to us for the purpose of receiving our email communications will never be rented or sold to a third party.`,
      },
    ],
  },
  {
    title: { text: `Protecting Your Information` },
    contents: [
      {
        text: `We work to protect the security of your information during transmission by using Secure Sockets Layer (SSL) software, which encrypts the information you input. We restrict authorized access to your personal information to those persons who have a legitimate purpose to know that information to provide products or services to you and those persons you have authorized to have access to such information.`,
      },
      {
        text: `Steedit. follows generally accepted industry standards to protect the personal information submitted to us, both during transmission and once Steedit. receives it. No method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, while Steedit. strives to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.`,
      },
      {
        text: `We will not sell, distribute, or lease your personal information to third parties unless we have your permission or are required by law to do so.`,
      },
    ],
  },
  {
    title: { text: `Rights` },
    contents: [
      {
        text: `Users who provide information through our website, as data subjects and data owners, have the right to access, rectify, download, or delete their information, as well as to restrict and object to certain processing of their information. While some of these rights apply, others apply only in certain limited circumstances. We describe these rights below:`,
      },
      {
        bullets: [
          {
            text: `<b>Access and portability:</b> to access and know what information is stored on our servers, you can send us your request through our contact information.`,
            type: "text",
          },
          {
            text: `<b>Rectify, Restrict, Limit, delete:</b> You can also rectify, restrict, limit, or delete much of your information.`,
            type: "text",
          },
          {
            text: `<b>Right to be informed:</b> Users of our website will be informed, upon request, about what data we collect, how it is used, how long it is retained and whether it is shared with third parties.`,
            type: "text",
          },
          {
            text: `<b>Object:</b> When we process your information based on our legitimate interests as explained above, or in the public interest, you may object to this processing in certain circumstances. In such cases, we will stop processing your information unless we have compelling legitimate reasons to continue processing it or where it is necessary for legal reasons.`,
            type: "text",
          },
          {
            text: `<b>Revoke consent:</b> Where you have previously given your consent, such as to allow us to process and store your personal information, you have the right to revoke your consent to the processing and storage of your information at any time. For example, you may withdraw your consent by updating your settings. In certain cases, we may continue to process your information after you have withdrawn your consent if we have a legal basis for doing so or if your withdrawal of consent was limited to certain processing activities.`,
            type: "text",
          },
          {
            text: `<b>Complaint:</b> If you wish to file a complaint about our use of your information (and without prejudice to any other rights you may have), you have the right to do so with your local supervisory authority. Users can exercise all these rights by contacting us through the contact information or the contact page.`,
            type: "text",
          },
          {
            text: `<b>Rights related to automated decision-making, including profiling:</b> website users may request that we provide a copy of the automated processing activities we conduct if they believe that data is being unlawfully processed.`,
            type: "text",
          },
        ],
      },
      {
        text: `Additionally, if you are a European resident, we note that we are processing your information to fulfil contracts we might have with you or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Europe.`,
      },
    ],
  },
  {
    title: { text: "CalOPPA" },
    contents: [
      {
        text: `California Online Privacy Protection Act Compliance (CalOPPA) is the first state law in the country to require commercial websites and online services to post a privacy policy. The scope of the law extends beyond California to require any person or company in the United States (and the world) that operates websites that collect personally identifiable information from California consumers to post a conspicuous privacy policy on its website stating exactly what information is collected and the persons or companies with whom it is shared.`,
        type: "text",
      },
      {
        text: `Because we value your privacy, we have taken the necessary precautions to comply with the California Online Privacy Protection Act (CalOPPA).`,
      },
      {
        text: `Under the California Online Privacy Protection Act, you have the following rights:`,
      },
      {
        bullets: [
          {
            text: `Know what personal information is collected.`,
            type: "text",
          },
          {
            text: `Know if your personal information is sold or disclosed and to whom.`,
            type: "text",
          },
          { text: `Access your personal data at any time.`, type: "text" },
          {
            text: `Request deletion and amendment of your personal information.`,
            type: "text",
          },
          {
            text: `Not be discriminated against for exercising your privacy rights.`,
            type: "text",
          },
        ],
      },
      {
        text: `To exercise your rights regarding the privacy and protection of your personal data, please send us your request through our contact information.`,
      },
    ],
  },
  {
    title: { text: `Children’s Online Privacy Protection` },
    contents: [
      {
        text: `We are in compliance with the requirements of the California Online Privacy Protection Act (CalOPPA – U.S. regulation) regarding the protection of the personal data of minors. We do not collect any information from anyone under 13 years of age. Although our services are available for all ages, alcoholic beverage orders are not available for those under 18 years of age. Steedit. reserves the right to make the necessary verifications and request the necessary documentation to verify the age required to place alcoholic beverage orders. If you become aware that your child has provided us with personal information, please contact us. If we become aware that a child has provided us with personal information, we will take steps to delete that information, terminate that person's account, and restrict access to that person.`,
      },
    ],
  },
  {
    title: { text: `Editing and Deleting Information` },
    contents: [
      {
        text: `If you believe that any information, we are holding on to you is incorrect or incomplete, please write to or email us as soon as possible. We will promptly correct any information found to be incorrect. You can change, modify, rectify, and delete your Information at any time, please contact us through the contact information. To opt-out of Steedit. email, follow the instructions included in the email. Your request should be processed within 48 hours.`,
      },
    ],
  },
  {
    title: { text: `Third Parties` },
    contents: [
      {
        text: `Except as otherwise expressly included in this Privacy Policy, this document addresses only the use and disclosure of information Steedit. collects from you. If you disclose your information to others, whether other users or suppliers on Steedit., different rules may apply to their use or disclosure of the information you disclose to them. Steedit. does not control the privacy policies of third parties, and you are subject to the privacy policies of those third parties where applicable. Steedit. is not responsible for the privacy or security practices of other platforms on the Internet, even those linked to or from the Steedit. site. Steedit. encourages you to ask questions before you disclose your personal information to others.`,
      },
    ],
  },
  {
    title: { text: `Contact Us` },
    contents: [
      {
        text: `If you have questions or concerns about these Privacy Policy and the handling and security of your data, please contact us through our contact page or via the contact information below:`,
      },
    ],
  },
];
