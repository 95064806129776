import styled from "styled-components/native";
import { Button } from "../../Atoms/Button";
import { Logo } from "../Logo";

const HeaderWrapper = styled.SafeAreaView`
  height: 64px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  background-color: white;
`;

const Row = styled.View`
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export const Header = (props: any) => {
  return (
    <HeaderWrapper>
      <Logo title={props.title} />
      <Row>
        <Button varient="border" title="Login" />
        <Button title="Register" />
      </Row>
    </HeaderWrapper>
  );
};
