import { useNavigation } from "@react-navigation/native";
import { Image } from "react-native";
import styled from "styled-components/native";
import { Button } from "../../Atoms/Button";
import { TextWrapper } from "../../Atoms/Text";
import { Logo } from "../Logo";

const HeroWrapper: any = styled.View`
  height: ${(props: any) => props.height || "auto"};
  flex-direction: row;
  background-color: ${(props: any) =>
    props.color ? props.theme.appColor2 : "white"};

  padding: 5%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const FooterMain = styled(TextWrapper)`
  font-weight: 700;
  color: white;
  margin-bottom: 20px;
`;

const FooterSubButton = styled.TouchableOpacity`
  font-weight: 700;
  color: white;
`;

const FooterSubText = styled(TextWrapper)`
  font-weight: 400;
  color: white;
  margin: 8px 0%;
`;

const View = styled.View``;

const CompanyView = () => {
  const { navigate } = useNavigation();
  const tt = [
    { title: "About us", navigate: "About" },
    { title: "Privacy Policy", navigate: "Privacy" },
    { title: "Terms and Conditions", navigate: "Terms" },
  ];
  return (
    <View>
      <FooterMain>Company</FooterMain>

      {tt.map((t: any) => (
        <FooterSubButton key={t.title} onPress={() => navigate(t.navigate)}>
          <FooterSubText>{t.title}</FooterSubText>
        </FooterSubButton>
      ))}
    </View>
  );
};

const DriverView = () => {
  return (
    <View>
      <FooterMain>Driver</FooterMain>

      <FooterSubButton>
        <FooterSubText>{"Become a Driver"}</FooterSubText>
      </FooterSubButton>
      <FooterSubButton>
        <Image
          style={{ height: 64, width: 120 }}
          source={require("../../images/google_play_white.png")}
          resizeMode="contain"
        />
      </FooterSubButton>
    </View>
  );
};
const ParnerView = () => {
  return (
    <View>
      <FooterMain>Parners</FooterMain>

      <FooterSubButton>
        <FooterSubText>{"Sign up"}</FooterSubText>
      </FooterSubButton>
      <FooterSubButton>
        <Image
          style={{ height: 64, width: 120, marginLeft: -50 }}
          source={require("../../images/app_store_white.png")}
          resizeMode="contain"
        />
      </FooterSubButton>
    </View>
  );
};

const Row = styled.View`
  flex-direction: row;
  align-items: center;
  gap: 8px;
  height: 56px;
`;
const SocialView = () => {
  return (
    <View>
      <FooterMain>Social media</FooterMain>

      <Row>
        <Image
          style={{ height: 40, width: 40 }}
          source={require("../../images/wa_white.png")}
          resizeMode="cover"
        />
        <Image
          style={{ height: 40, width: 40 }}
          source={require("../../images/twitter_white.png")}
          resizeMode="cover"
        />
        <Image
          style={{ height: 40, width: 40 }}
          source={require("../../images/insta_white.png")}
          resizeMode="cover"
        />
        <Image
          style={{ height: 40, width: 40 }}
          source={require("../../images/fb_white.png")}
          resizeMode="cover"
        />
      </Row>
    </View>
  );
};

export const Footer = () => {
  return (
    <HeroWrapper color={true}>
      <Logo sub title="Steedit" />
      <CompanyView />
      <DriverView />
      <ParnerView />
      <SocialView />
    </HeroWrapper>
  );
};
