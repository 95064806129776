// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  collection,
  getFirestore,
  query,
  where,
} from "firebase/firestore/lite";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyCJgtTqP4cNv_XaB9F0cV_DQJtlUSCVgLc",
//   authDomain: "steedit-dev.firebaseapp.com",
//   projectId: "steedit-dev",
//   storageBucket: "steedit-dev.appspot.com",
//   messagingSenderId: "794497328320",
//   appId: "1:794497328320:web:f8442d219e2e7294d97925",
//   measurementId: "G-ZS2QY98BQD",
// };

const firebaseConfig = {
  apiKey: "AIzaSyAx5Dfx609bBj-cgOg4vuESYh2u5EVj564",
  authDomain: "steedit-b2b54.firebaseapp.com",
  databaseURL: "https://steedit-b2b54.firebaseio.com",
  projectId: "steedit-b2b54",
  storageBucket: "steedit-b2b54.appspot.com",
  messagingSenderId: "744718709004",
  appId: "1:744718709004:web:d32934faeb8b21a8afd929",
  measurementId: "G-5VWELF3VE2",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export default app;

export const db = getFirestore(app);

export const categoriesData = collection(db, "categories");
export const placessData = collection(db, "places");
export const placesQueryWithCity = (city: String[]) =>
  query(placessData, where("location.city", "in", city));
// export const categoriesData = collection(db, 'managers');
