export const TERMS = [
  {
    title: { text: "Terms & Conditions" },
    contents: [
      {
        text: `The Steedit. mobile application and website together with any materials and services available therein, and successor site(s) thereto (referred to as the “Website”), which are operated by Steedit. LLC and its subsidiaries (“Steedit.” or “we” or “us” or “our”).`,
        type: "text",
      },
      {
        text: `These Terms of Use (“Terms and Conditions”) state the terms and conditions governing your use of and access to the mobile application and constitute a legally binding contract between you and Steedit. These Terms incorporate any additional terms and conditions posted by Steedit. through the mobile application, or otherwise made available to you by Steedit.`,
        type: "text",
      },
      {
        text: `Please read the Terms and Conditions set out below carefully before ordering any food products from our mobile application interface. We reserve the right to unilaterally amend these Terms and Conditions at any time by posting the amended terms on this mobile application, without notice to you. Please check back periodically for updates and additions. By ordering any Food Products through our mobile application or website, you agree to be bound by these Terms and Conditions, as amended from time to time. Where applicable, these Terms and Conditions form part of the contract with a Participating merchant for the supply of food products.`,
        type: "text",
      },
    ],
  },
  {
    title: { text: `Definitions` },
    contents: [
      {
        sub: `"Agreement"`,
        text: ` is a reference to these Terms and Conditions, the Privacy Policy, any order form and payment instructions provided to you.`,
      },
      {
        sub: `"Event of Force Majeure”`,
        text: ` means any war, civil disorder, act of government, fire, earthquake, act of God or other factor beyond the reasonable control of either party.`,
        type: "text",
      },
      {
        sub: `"Food Products"`,
        text: ` is a reference to any food products which we may offer for sale through our website or mobile application from time to time.`,
        type: "text",
      },
      {
        sub: `"Participating merchants"`,
        text: ` means any restaurant who lists food products on the mobile application or website.`,
        type: "text",
      },
      {
        sub: `"Privacy Policy"`,
        text: ` means the policy displayed on our website which details how we collect and store your personal data;`,
        type: "text",
      },
      {
        sub: `“Steedit. email”`,
        text: ` means support@Steedit.com`,
        type: "text",
      },
      {
        sub: `“User”`,
        text: ` means a person who registers with the mobile application, who is bound by these Terms and Conditions.`,
        type: "text",
      },
      {
        sub: `"You", "your" and "yours"`,
        text: ` are references to you, the person accessing the website and ordering any food products from our mobile application or Website or from any other channels provided by Steedit.`,
        type: "text",
      },
      {
        sub: `"Website"`,
        text: ` is a reference to our website www.Steedit.com or our mobile applications on which we offer our Services.`,
        type: "text",
      },
    ],
  },
  {
    title: { text: `Who Is Steedit. And What Do We Do` },
    contents: [
      {
        text: `Steedit. provides, as an intermediary service, an online platform for (i) Participating merchants to advertise and market food products; and (ii) customers to place orders for the food products for the price listed on the website, which Steedit. communicates with the participating merchants for preparation. Steedit. is not a delivery entity, and not associated with any courier carrier or logistic company. Steedit. is solely a technology provider and does not participate in any activity of supplying food, groceries or other products related.`,
        type: "text",
      },
      {
        text: `Steedit. is appointed by each participating merchants to act as agent to arrange the delivery of food products to the address provided by you. Delivery orders are carried by our delivery drivers who are strictly not Steedit. employees or agents, but instead operate as independent contractors through binding agreements entered into with Steedit. LLC. Steedit. is not the retailer of any products offered by restaurants.`,
        type: "text",
      },
    ],
  },
  {
    title: { text: `License` },
    contents: [
      {
        text: `Subject to and conditioned on your compliance with these Terms and Conditions, Steedit. grants you a limited, non-exclusive, non-transferable, non-assignable, revocable license to access and use the mobile application, and to download copies of the materials that we make available for download on the mobile application interface, in each case solely for your personal and non-commercial use.`,
        type: "text",
      },
      {
        text: `The mobile application and website, including all content, information, and materials incorporated into or made available through the website, are the exclusive property of Steedit. or its suppliers, partners, and affiliates, and is protected by United States and international law. You agree not to access or use the website, or any content, information, or materials incorporated into or made available through the website, except as expressly permitted under these terms.`,
        type: "text",
      },
      {
        text: `All trademarks, service marks, and logos displayed on the website (the “Marks”) are the exclusive property of Steedit. or their respective third-party owners. Except for your right to view marks on our website, you are not granted any rights to the marks. Nothing in the website grant, by implication, estoppel, or otherwise, any license or right to use any marks displayed on the website.`,
        type: "text",
      },
    ],
  },
  {
    title: { text: `Account Registration` },
    contents: [
      {
        text: `To use and enjoy the services we provide, we require you to register an account with us at no cost. Your account is for your individual personal use only, and you may not authorize others to use your account for any purpose. In creating your account, you certify that all information you provide is complete and accurate. You agree to update your information when required or requested, and you further agree not to use another person’s account without permission. You are responsible for maintaining the confidentiality of, and restricting access to your account and password, and you agree to accept sole responsibility for all activities that occur under your account or password. You agree to contact our customer service department immediately of any breach of security or unauthorized use of your account or any violation of these Terms by others of which you are aware. You agree that we shall have no liability for any losses, damages, liabilities, or expenses you may incur due to any unauthorized use of your account, and you agree to indemnify us and hold us harmless for any such unauthorized use. We reserve the right to create accounts for quality control and administrative purposes. You must notify us of any change to your registration details.`,
        type: "text",
      },
      {
        text: `You agree that you are above the age of 13 and, if you are under the age of 18, have received the permission of your parents or legal guardian to use the mobile application, website, and the Services.`,
        type: "text",
      },
      {
        text: `You agree to use the mobile application, website and Services in a manner that does not violate any applicable law.`,
        type: "text",
      },
      {
        text: `Steedit. reserves the right to cancel the user registration of and/or refuse orders of food products to anyone who does not comply with the above confirmations, or who otherwise breaches these terms and conditions.`,
        type: "text",
      },
      {
        text: `Should you wish to close your account, you may do so by contacting us through the Steedit. email.`,
        type: "text",
      },
    ],
  },
  {
    title: { text: `Third Party Materials` },
    contents: [
      {
        text: `The website may make available or provide links to third party websites, content, services, or information (“Third Party Materials”). Steedit. does not control, and is not responsible for, any Third-party materials and the availability of any third-party materials on the website does not imply endorsement of, or affiliation with the provider of third-party materials. Your use of third-party materials is at your own risk.`,
        type: `text`,
      },
    ],
  },
  {
    title: { text: `Ordering` },
    contents: [
      {
        text: `To make an order through the mobile application you must either register as a user or enter your contact details in the form fields provided when you place an order. To register as a user, you must complete the registration form on the application. Any order that you place with us is subject to product availability, delivery capacity and acceptance by us and the participating merchant. Upon placing a pick up delivery order through the Steedit. mobile application, the closest delivery driver (contractor) to the merchant will be notified through the driver mobile application in addition to the order description. Furthermore, delivery drivers (contractors) will proceed with placing, paying the merchant for the order and fulfill the delivery. Customers will be able to modify an order while communicating with the delivery driver in the event that the items ordered initially are out of stock. You acknowledge and agree that orders that are placed and paid for by delivery drivers cannot be cancelled or refunded. After an order is placed, customers may receive email or text notification regarding the order information and delivery status`,
        type: "text",
      },
      {
        text: `This email or text confirmation will be produced automatically to provide confirmation of your order details. In the event the confirmation is not correct, you must notify us immediately for swift action by us. Receipt of an automatic confirmation does not necessarily mean that either we or the participating merchants will be able to fill your order. Once we have sent the confirmation email, text we will check availability and delivery capacity. You will receive an email confirming if the ordered food products and delivery capacity are available.`,
        type: "text",
      },
      {
        text: `You warrant and agree to provide us with clear, concise, and accurate information at the time of submitting an order using our application. You also warrant and agree that the credit or debit card details that you provide are for your own credit or debit card and that you have sufficient funds to make the payment.`,
        type: "text",
      },
      {
        text: `You acknowledge, agree to pay for the order placed in addition to all fees associated that includes taxes, tips, gratuity fees and more.`,
        type: "text",
      },
      {
        text: `The agreement for the supply of any food products you ordered through the mobile application will be between you and the partner merchant. You warrant and agree that any food products ordered by you shall be for your sole consumption and not for any resale`,
        type: "text",
      },
    ],
  },
  {
    title: { text: `Prices and Payment` },
    contents: [
      {
        text: `Once an order is placed, you are responsible and charged for:`,
        type: "text",
      },
      {
        bullets: [
          { text: `The total amount of the item purchased` },
          { text: `A service fees that varies based on the order cost` },
          {
            text: `A delivery fee calculated based on distance, time and more`,
          },
          { text: `Tips exclusively for delivery drivers` },
          { text: `Taxes and other costs associated with the order.` },
        ],
      },
      {
        text: `The price for each food product on the mobile application is located next to the item. You acknowledge that the price may change at any time. All prices on our mobile application interface food ordering are in American dollars.`,
      },
      {
        text: `A delivery charge will be added to each order in addition to the price of the food products (“delivery charge”), which is calculated via the application based on your location. You are responsible for ensuring that your location is recorded accurately by the application, and we do not accept responsibility for any such erroneous information.`,
      },
      {
        text: `The total price for the food products ordered and delivery charge(s) (“total price”) will be displayed on the mobile application when you place your order. You must pay the total price before your order is delivered. Failure to pay will result in the cancellation of your order.`,
      },
      {
        text: `When you place an order through the mobile application you will be given a choice of payment via Stripe. “Steedit.” will be the name that appears on the stripe. As set out above, however, Steedit. is not the seller of any of the food products, and these gateways are not operated by Steedit. Processing of payments is subject to the additional terms of Stripe as the case may be.`,
      },
      {
        text: `All sums paid by you under these Terms and Conditions shall be made in full without deduction or setoff.`,
      },
      {
        text: `You acknowledge that the prices of food products, delivery charges and any other charges set out on our mobile application and website may change at any time.`,
      },
    ],
  },

  {
    title: { text: `Delivery` },
    contents: [
      {
        text: `Delivery periods provided at the time of ordering are estimated and may vary. Any order tracking information provided to users through the application is equally estimated. Food will be delivered to the address designated by you at the time of ordering.`,
      },
      {
        text: `If your order is not delivered within the estimated delivery time provided by us, please contact Steedit. first. You may contact us by email via the Steedit. email for our further action.`,
      },
      {
        text: `We and the participating merchants, who will prepare your order, aim to (a) deliver the product to you at the place of delivery specified by you in your order; (b) deliver within the estimated delivery time; and (c) inform you if we expect that we will be unable to meet the estimated delivery time.`,
      },
      {
        text: `In case of a late delivery or mis-delivery, the Delivery Charge may only be refunded by Steedit. in accordance with the “Refunds and Cancellations” section below. Steedit. shall not be otherwise liable to you for any losses, liabilities, costs, damages, charges, or expenses arising out of late or mis-delivery or for any inaccurate order tracking information provided through the mobile application and website. The title and risk of the food products shall be passed to you upon delivery.`,
      },
      {
        text: `Subject to the “Refunds and Cancellations” section below, if you fail to accept delivery of food products at the time they are ready for delivery, or we are unable to deliver at the nominated time due to your failure to provide appropriate instructions, or authorizations, then such food products shall be deemed to have been delivered to you and all risk and responsibility in relation to such food products shall pass to you. Any storage, insurance and other costs which we incur as a result of the inability to deliver shall be your responsibility and you shall indemnify us in full for such cost.`,
      },
      {
        text: `You must ensure that, at the time of delivery of food products, adequate arrangements, including access where necessary, are in place for the safe delivery of such food products. We cannot be held liable for any damage, cost or expense incurred to such food products or premises where this arises as a result of failing to provide adequate access or arrangements for delivery.`,
      },
      {
        text: `You acknowledge, agree that Steedit. LLC and its delivery drivers may have to communicate with you regarding the status of the current order you placed on the application via phone, email, text message and more. Also, we may contact you regarding promotion offers or deals at a given time using the same channels of communication listed above.`,
      },
      {
        text: `Upon receiving your order fully completed by the delivery driver, your Steedit. application will display a rating screen that will allow you to rate your delivery driver according to the service provided to you.`,
      },
      {
        text: `Please note that it might not be possible for us to deliver to some locations. If this is the case, we will endeavor to inform you using the contact details that you provide to us when you make your order and arrange for cancellation of the order or delivery to an alternative delivery address.`,
      },
    ],
  },

  {
    title: { text: `Refunds and Cancellations` },
    contents: [
      {
        text: `Cancellation policy: By completing and paying for your order you are permitting the selected partner merchant to begin preparation of your order. You will receive an email notifying you the partner merchant has commenced preparation of your order. Once preparation has commenced, you will not be able to cancel your order and any refusal to accept the delivered order will not entitle you to a refund.`,
      },
      {
        text: `Refund policy: If for any reason you are not completely satisfied with the service you receive from Steedit. please contact us through the Steedit. Email. If you are not satisfied with the Meals you’ve received from one of our partner merchants, please be in direct contact with the merchant or you can email any feedback to us through the Steedit. email.`,
      },
      { text: `All refunds are completely at the discretion of Steedit.` },
    ],
  },
  {
    title: { text: `User Submitted Information` },
    contents: [
      {
        text: `We may request you to provide us with feedback related to the food product, on our mobile application or otherwise. Such Feedback will be deemed user submitted information, and you hereby acknowledge and agree that such feedback is not confidential, and that your provision of such feedback is gratuitous, unsolicited and without restriction, and does not place Steedit. under any fiduciary or other obligation.`,
      },
    ],
  },
  {
    title: { text: `Age Restriction Products` },
    contents: [
      {
        text: `Customers may choose to order products that are restricted due to age, including alcoholic items from merchants listed within our network. Such items, if ordered, must go through the age verification process while performing delivery for customers identity check.`,
      },
      { text: `If you ordered alcoholic beverages:` },
      {
        bullets: [
          {
            text: `You agree to provide a valid government issued ID to verify your identity`,
          },
          {
            text: `You may order, receive alcohol if you are 21 years and above`,
          },
          {
            text: `You may not receive alcoholic beverage order if intoxicated during delivery`,
          },
        ],
      },
    ],
  },

  {
    title: { text: `User Obligations` },
    contents: [
      {
        text: `Users are fully responsible for the proper use of, and access to, their accounts in accordance with the current legislation, as well as with the principles of good faith, morals, generally accepted customs and public order.`,
      },
      {
        text: `Users shall refrain from using their account for illegal purposes or with illegal results that harm third-party rights and interests or that may in any way damage, disable, affect, or impair the mobile application and its contents and services.`,
      },
      {
        text: `Any persons who breach the above obligations shall be liable for any loss or damage caused by them. Steedit. will accept no liability for any consequences, loss or damage that may arise from such illegal use or access by third parties.`,
      },
      {
        text: `In general, users undertake, by way of example without limitation:`,
      },
      {
        bullets: [
          {
            text: `To refrain from altering or modifying the mobile application and website, in full or in part, by bypassing, disabling or in any other way tampering with, its functions or services.`,
          },
          {
            text: `To refrain from infringing industrial and intellectual property rights or the personal data protection legislation.`,
          },
          {
            text: `To refrain from using the mobile application and website to insult, defame, intimidate or harass other users or partner merchants, or attack their image.`,
          },
          {
            text: `To refrain from introducing computer viruses, corrupted files or any other software that may cause damage or alterations to Steedit.’s or third parties’ contents or systems.`,
          },
        ],
      },
      {
        text: `Steedit. reserves the right to remove and/or suspend any User for breach of these General Terms of Use.`,
      },
    ],
  },
  {
    title: { text: `Linked Sites` },
    contents: [
      {
        text: `There may be a few links on our website to third party websites which we believe may be of interest to you. We do not represent the quality of the goods or services provided by such third parties nor do we have any control over the content or availability of such sites. We cannot accept any responsibility for the content of third-party websites or the Services or goods that they may provide to you.`,
      },
    ],
  },
  {
    title: { text: `Limitation of Liability` },
    contents: [
      {
        text: `The Participating merchants are solely responsible for preparing and packaging all food products ordered through the mobile application and website and updating their menu and menu prices. Each participating merchant is solely liable for the quality and freshness of its products, and Steedit. does not verify the credentials, representations, products, or prices offered by the participating merchants, and does not guarantee the quality of the product, or that any participating merchant or food product complies with applicable laws. Steedit. is unable to check orders of food products produced by participating merchants for food safety reasons.`,
      },
      {
        text: `Steedit. shall not be liable or responsible for any food products or services provided by participating merchants, or any information provided through the mobile application and website, that is a cause of injury or that is unacceptable to or does not meet your expectations in any manner.`,
      },
      {
        text: `We reserve the right to alter the food products available for sale via our mobile application, website and to stop listing participating merchants and/or food products.`,
      },
      {
        text: `The mobile application is owned and operated by Steedit. and is completely owned and operated in the United States. Steedit. reserves the right to modify or discontinue, temporarily or permanently, and at any time, the website and/or the services (or any part thereof) with or without notice. We accept no responsibility for any loss arising due to the unavailability of the mobile application or the services for any reason.`,
      },
      {
        text: `Great care has been taken to ensure that the information available on this mobile application and website is correct and error free. We apologize for any errors or omissions that may have occurred. We cannot warrant that use of the mobile application will be error free or fit for purpose, timely, that defects will be corrected, or that the site or the server that makes it available are free of viruses or bugs or represents the full functionality, accuracy, reliability of the mobile application and we do not make any warranty whatsoever, whether express or implied, relating to fitness for purpose, or accuracy.`,
      },
      {
        text: `By accepting these terms of use you agree to relieve us from any liability whatsoever arising from your use of information from any third party, or your use of any third-party website, or your consumption of any food products from a participating restaurant.`,
      },
      {
        text: `The food products sold through the mobile application are provided for private domestic and consumer use only. The Services are provided ‘as is’ and Steedit., together with its directors, officers, employees, agents, and subcontractors disclaim all warranties and any liability to you for the supply of the Services to the fullest extent permissible under applicable law. This does not affect your statutory rights as a consumer. If we are found liable for any loss or damage to you such liability is limited to the amount you have paid for the relevant food products and the delivery charges. We cannot accept any liability for any other loss, damage or expense, including any direct, indirect or consequential loss such as loss of income or profits to you, loss of damage to property and/or loss from claims of third parties, howsoever arising. This limitation of liability does not apply to personal injury or death arising as a direct result of our negligence.`,
      },
      {
        text: `You agree to release and indemnify and hold Steedit. and its officers, directors, agents, employees, and subcontractors harmless from and against any claims, demands, proceedings, losses and damages (actual, special and consequential) of every kind and nature, known and unknown, including legal fees on the indemnity basis, arising from or in relation to the services, including those made by any third party arising out of your breach of these Terms and Conditions, or your violation of any law or the rights of a third party.`,
      },
      {
        text: `We do not accept any liability for any delays, failures, errors or omissions or loss of transmitted information, viruses or other contamination or destructive properties transmitted to you or your computer system via our platforms.`,
      },
      {
        text: `We shall not be held liable for any failure or delay in performing services or delivering food products where such failure arises as a result of any act or omission, which is outside our reasonable control such as an Event of Force Majeure or third-party act.`,
      },
      {
        text: `If we have contracted to provide an identical or similar order to more than one customer and are prevented from fully meeting our obligations to you by reason of an event of force majeure, we may decide at our absolute discretion which orders we will fill and to what extent.`,
      },
      {
        text: `We have taken all reasonable steps to prevent Internet fraud and ensure any data collected from you is stored as securely and safely as possible. However, we cannot be held liable in the extremely unlikely event of a breach in our secure computer servers or those of third parties.`,
      },
    ],
  },
  {
    title: { text: `Intellectual Property` },
    contents: [
      {
        text: `You may use information on the Steedit. mobile application, website only for your personal, non-commercial informational purpose. No content may be copied, reproduced, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, or distributed in any way to any other computer, server, website or other medium (including hard copy) for publication or distribution or for any commercial enterprise, without Steedit.’s prior written consent.`,
      },
      {
        text: `You agree that if you submit ratings, suggestions, comments, or questions or post any other information on this site, you grant Steedit. the non-exclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content in any form, media, or technology. Steedit. takes no responsibility and assumes no liability for any content posted or submitted by you or by other Users.`,
      },
      {
        text: `You promise Steedit. that any such material provided by you does not breach the intellectual property rights of any person and agree to indemnify Steedit. against claims of any kind arising from a breach by you of that promise, including legal fees on the indemnity basis.`,
      },
    ],
  },
  {
    title: { text: `Data Text Messages and Email` },
    contents: [
      {
        text: `You may be able to access the mobile application through your mobile device, and we or a participating merchant may communicate with you via phone call and email as set out in these Terms and Conditions. Please be sure to check your data and messaging plans to understand any relevant costs incurred by your use of Steedit.’s services. We are not responsible for any fees charged by your data or cellphone carrier.`,
      },
      {
        text: `You consent to receive text messages from or on behalf of Steedit. as a part of the services at the cell phone number(s) provided by you to Steedit. You also agree that such text messages may be generated using an automatic telephone dialing system. You must inform us immediately if the cell phone number(s) you have provided to Steedit. are changed or disconnected.`,
      },
    ],
  },
  {
    title: { text: `Disclaimers` },
    contents: [
      {
        text: `Website information: We may make changes to the material on our mobile application and website, or to the service, products and prices described on it, at any time without notice.`,
      },
      {
        text: `Specials listed on the mobile application; website are subject to change. Partner merchants may alter their specials at any time, including removing or changing the terms of any loyalty discount program. Loyalty credits earned through a loyalty discount program from a merchant are non-transferable and are not redeemable for cash.`,
      },
      { text: `Allergy, Dietary and Other Menu Information` },
      {
        text: `We try to accurately copy the item names, descriptions, prices, special offer information, heat and allergenic warnings and other information from the menus that are provided to us by partner merchants. However, it is the partner merchants that are responsible for providing this menu information and ensuring that it is factually accurate and up-to-date, and we do not undertake any such responsibility. If you are in doubt about allergy warnings, contents of a dish or any other menu information, you should confirm with the partner merchant directly before ordering. Some of the pictures shown are indicative of the style of cuisine only and may not be an actual photo of the dish from the partner merchant.`,
      },
      {
        text: `Partner merchant actions and omissions: The legal contract for the supply and purchase of products is between you and the partner merchant that you place your order with. We have no control over the actions or omissions of any partner merchants. Without limiting the generality of the foregoing, you acknowledge and accept the following by using the mobile application and website:`,
      },
      {
        bullets: [
          {
            text: `We do not give any undertaking that the food products ordered from any partner merchant through the mobile application will be of satisfactory quality or suitable for your purpose and we disclaim any such warranties.`,
          },
          {
            text: `Estimated times for deliveries and collections are provided by the partner merchants and are only estimates. Neither we nor the partner merchants guarantee that orders will be delivered or will be available for collection within the estimated times.`,
          },
          {
            text: `Merchants have the discretion to reject orders at any time because they are too busy, or due to weather conditions or for any other reason. We encourage all our partner merchants to accept all orders and to communicate any rejection promptly, and we will notify you (generally by email) as soon as reasonably practicable if a partner merchant rejects your order.`,
          },
          {
            text: `The foregoing disclaimers do not affect your statutory rights against any partner merchant.`,
          },
        ],
      },
      {
        text: `Exclusion of terms: We provide you with access to the mobile application, website and service on the basis that, to the maximum extent permitted by law, we exclude all representations, warranties, conditions, undertakings and other terms in relation to the website and service (including any representations, warranties, conditions, undertakings and other terms which might otherwise apply to the mobile application, or be otherwise implied or incorporated into these website terms, by statute, common law or otherwise).`,
      },
    ],
  },
  {
    title: { text: `General` },
    contents: [
      {
        text: `We may subcontract any part or parts of the services that we provide to you from time to time and we may assign or novate any part or parts of our rights under these Terms and Conditions without your consent or any requirement to notify you.`,
      },
      {
        text: `The Terms and Conditions, any order form and payment instructions constitute the entire agreement between you and us (“Agreement”). No other terms, whether expressed or implied, shall form part of this agreement. In the event of any conflict between these Terms and Conditions and any other term or provision on the mobile application and website, these Terms and Conditions shall prevail.`,
      },
      {
        text: `If any Term or Condition shall be deemed invalid, illegal or unenforceable, the parties hereby agree that such Term or Condition shall be deemed to be deleted and the remainder of the Agreement shall continue in force without such Term or Condition.`,
      },
      {
        text: `No delay or failure on our part to enforce our rights or remedies under the Agreement shall constitute a waiver on our part of such rights or remedies unless such waiver is confirmed in writing.`,
      },
      {
        text: `These Terms and Conditions (and all non-contractual obligations arising out of or connected to them) shall be governed and construed in accordance with the laws of the state of California. Both we and you hereby submit to the non-exclusive jurisdiction of California state courts. All dealings, correspondence, and contracts between us shall be made or conducted in the English language.`,
      },
    ],
  },
  {
    title: { text: `Security and Data Retention` },
    contents: [
      {
        text: `We take steps to protect your information from unauthorized access, modification, or disclosure and against misuse, interference, loss, destruction, and damage. Once your information is no longer required for any purpose for which it may be used or disclosed by us, and we are not required by law to retain the information, we will destroy the information or ensure that it is de-identified.`,
      },
      {
        text: `All user details captured by Steedit. are always stored securely and will never be provided to any unauthorized third parties. Steedit. will never save your actual credit card details. We will simply save a reference to your card, which is called a token. This token is provided to us by the payment provider. Saving the token means that for future credit card transactions we can give you the option to charge the same card you used previously without having to re-enter the card details. If, at any time, you wish the token to be removed please email us through the Steedit. email and we will have these removed. In that case you would need to re-enter your card details on the next order.`,
      },
      {
        text: `However, the transmission of information via the internet is not completely secure. Although we will take reasonable steps to protect your information and make sure it is safe and secure and we use a number of physical, administrative, personnel and technical measures to protect your personal information, we cannot guarantee the security of your data transmitted to the mobile application; any transmission is at your own risk. For the avoidance of doubt, Steedit. will not in any circumstances be liable to you, or third parties, for loss or damage arising from credit card fraud or identity theft.`,
      },
    ],
  },
  {
    title: { text: `Disputes: You Agree to Mandatory Arbitration` },
    contents: [
      {
        text: `In the event there are any disputes or controversies that arise between the parties pursuant regarding the current terms of service, then the parties are waiving their right to litigate these issues in court instead elect to have these disputes resolved through arbitration.`,
      },
      {
        text: `The parties agree that any disputes are to be arbitrated through the American Arbitration association and that the parties agree to abide by the rules of the commercial Arbitration Rules of the American Arbitration Association.`,
      },
      {
        text: `WHEREFORE, it is agreed that all claims and disputes arising or relating to the current terms of service are to be settled by binding arbitration in the state of California. Said arbitration is to be resolved through the commercial Arbitration Rules of the American Arbitration Association and the parties agree to abide by these rules.`,
      },
      {
        text: `Any decision or award as a result of any such arbitration shall be issued in writing and the arbitrator shall be mutually selected pursuant to the Commercial Arbitration Rules of the American Arbitration Association.`,
      },
    ],
  },

  {
    title: { text: `Contact Us` },
    contents: [
      {
        text: `If you have any questions or complaints about the Terms or the Policy or if you would like to update any personal information you have provided to us, please email us at support@Steedit.com.`,
      },
      { text: `Last updated: January 15, 2022` },
    ],
  },
];
