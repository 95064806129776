import styled from "styled-components/native";
import { TextWrapper } from "../Atoms/Text";
const LogoDot: any = styled.View`
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: ${(props: any) =>
    props.color ? props.color : props.theme.appColor};
`;

const Row = styled.View`
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;
const LogoText: any = styled(TextWrapper)`
  font-size: 18px;
  font-weight: 700;
  color: ${(props: any) => (props.color ? props.color : "black")};
`;

export const Logo = (props: any) => {
  if (props.sub) {
    return (
      <Row>
        <LogoDot color="white" />
        <LogoText color="white">{props.title}</LogoText>
      </Row>
    );
  }
  return (
    <Row>
      <LogoDot />
      <LogoText>{props.title}</LogoText>
    </Row>
  );
};
