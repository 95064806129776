import styled from "styled-components/native";
import AntDesign from "@expo/vector-icons/AntDesign";
import MaterialDesign from "@expo/vector-icons/MaterialIcons";
const View = styled.View``;

const Touch = styled.TouchableOpacity``;
const ButtonView: any = styled.View`
  height: 48px;
  width: 48px;
  border-radius: 36px;

  align-items: center;
  justify-content: center;
  ${(props: any) =>
    props.active
      ? `
    box-shadow: 0px 0px 10px #0002;
  `
      : `
  border: 1px solid lightgray;
  `};
`;

const PagingButton = (props: any) => (
  <Touch disabled={!props.active} onPress={props.onClick}>
    <ButtonView active={props.active}>{props.children}</ButtonView>
  </Touch>
);

interface PageType {
  total: number;
  active: number;
  onLeft: () => void;
  onRight: (i: number) => void;
  limit: number;
}
export const Pagination = (props: PageType) => {
  let pp = Array.from(
    { length: Math.ceil(props.total / props.limit) },
    (x, i) => i + 1
  );
  return (
    <View
      style={{
        alignSelf: "flex-end",
        flexDirection: "row",
        gap: 8,
        alignItems: "center",
        marginVertical: 8,
      }}
    >
      <PagingButton active={true} onClick={props.onLeft}>
        <AntDesign name="arrowleft" size={24} />
      </PagingButton>

      {pp.map((p) =>
        props.active == p ? (
          <MaterialDesign size={16} name="radio-button-on" />
        ) : (
          <MaterialDesign size={16} name="radio-button-off" />
        )
      )}

      <PagingButton
        active={true}
        onClick={() => props.onRight(props.active + 1)}
      >
        <AntDesign name="arrowright" size={24} />
      </PagingButton>
    </View>
  );
};
