import { View } from "react-native";
import styled from "styled-components/native";
import { Button, ButtonWrapper } from "../../Atoms/Button";
import { TextWrapper } from "../../Atoms/Text";
import { Form } from "./Form";

const Wrapper = styled.View`
  top: 15%;
  left: 10%;
  width: 35%;
  background-color: #fff3;
  border-radius: 20px;
  padding: 2%;
  z-index: 100;
`;

const Title = styled(TextWrapper)`
  font-size: 48px;
  font-weight: 800;
  color: white;
`;

const Row = styled(View)`
  flex-direction: row;
  align-items: center;
  gap: 8px;
  height: 56px;
`;

const NormalText = styled(TextWrapper)`
  color: white;
`;

export const HomeSearchForm = () => {
  return (
    <Wrapper>
      <Title>Order Now</Title>
      <Form />
      <PlatformIcons />
    </Wrapper>
  );
};

const PlatformIcons = () => {
  return (
    <>
      <NormalText>Get the App</NormalText>
      <Row>
        <Button
          varient="icon"
          title="Android"
          icon={require("../../images/apple_logo.png")}
          onClick={() => console.log("HEY")}
        />
        <Button
          varient="icon"
          icon={require("../../images/android_logo.png")}
          onClick={() => console.log("HEY")}
        />
      </Row>
    </>
  );
};
