import { Image, View } from "react-native";

import styled from "styled-components/native";
import { Button } from "../../Atoms/Button";
import { TextWrapper } from "../../Atoms/Text";
import { Footer } from "../../Components/Footer";
import { Hero } from "../../Components/Hero";
import { HomeSearchForm } from "../../Components/HomeSearchForm";
import { Layout } from "../../Components/Layout";
const PHONE_FRAME = require("../../images/phone_frame.png");

const HeroWrapper: any = styled.View`
  min-height: ${(props: any) => props.height || "auto"};
  flex-direction: row;
  background-color: white;
  background-color: ${(props: any) =>
    props.color ? props.theme.appColor : "white"};
`;

const OrderWithText = styled(TextWrapper)`
  font-size: 42px;
  font-weight: 900;
  width: 40%;
  color: ${(props: any) => props.theme.appColor};
`;

const DescText = styled(TextWrapper)`
  font-size: 20px;
  margin: 5% 0%;
  width: 80%;
  letter-spacing: 1.2px;
`;

const Text = styled(TextWrapper)`
  font-size: 18px;
`;

const Row = styled.View`
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const OrderWithSteed = () => (
  <HeroWrapper height="600px">
    <View style={{ flex: 1, paddingTop: "5%" }}>
      <Image
        style={{ height: 600 }}
        source={PHONE_FRAME}
        resizeMode="contain"
      />
    </View>
    <View style={{ flex: 1, paddingTop: "5%" }}>
      <OrderWithText>Order With Steedit</OrderWithText>
      <DescText>
        Steedit. Is your local food delivery platform on demand. Place your
        order within your favorite restaurants, grocery, and convenient stores
        available on our network and it will be delivered at your doorsteps.
        “You Steedit.! You get it!
      </DescText>
      <View>
        <Text>Download the App</Text>
        <Row>
          <Button
            varient="image"
            image={require("../../images/google_play_color.png")}
          />
          <Button
            varient="image"
            image={require("../../images/app_store_color.png")}
          />
        </Row>
      </View>
    </View>
  </HeroWrapper>
);

const ImageBackground = styled.ImageBackground`
  flex: 1;
  background-color: #000;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

export const HomeScreen = () => {
  return (
    <Layout>
      <Hero height="600px">
        <HomeSearchForm />
      </Hero>
      <OrderWithSteed />
      <HeroWrapper height="600px">
        <ImageBackground source={require("../../images/delivery_boy.png")}>
          <Text
            style={{
              fontWeight: 700,
              fontSize: 48,
              color: "white",
              textShadow: "20px 0px 30px black",
            }}
          >
            Deliver with Steedit
          </Text>
          <Button title="Earn Now" />
        </ImageBackground>
        <ImageBackground source={require("../../images/chef_lady.png")}>
          <Text
            style={{
              fontWeight: 700,
              fontSize: 48,
              color: "white",
              textShadow: "20px 0px 30px black",
            }}
          >
            Become a Partner
          </Text>
          <Button title="Start Now" />
        </ImageBackground>
      </HeroWrapper>
    </Layout>
  );
};
