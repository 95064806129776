import styled from "styled-components/native";
import { Footer } from "../Footer";

const Wrapper = styled.ScrollView`
  flex: 1;
`;

export const Layout = (props: any) => {
  return (
    <Wrapper>
      {props.children}
      <Footer />
    </Wrapper>
  );
};
