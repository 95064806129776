export const googleLocationApi = async (searchText: String) => {
  const apiResult = await fetch(
    `https://us-central1-steedit-dev.cloudfunctions.net/getLivePlaces?input=${searchText}&types=geocode&language=en`
  );
  const { predictions, status } = await apiResult.json();
  if (status !== "OK") {
    return [];
  }
  return predictions.map(({ place_id, structured_formatting }: any) => ({
    place_id,
    main: structured_formatting.main_text,
    sub: structured_formatting.secondary_text,
  }));
};
