import { View } from "react-native";

import * as React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { HomeScreen } from "./src/Screens/Home";
import { ExploreScreen } from "./src/Screens/Explore";
import { Header } from "./src/Components/Header";
import { ThemeProvider } from "styled-components/native";
import { theme } from "./src/theme";
import { useFonts } from "expo-font";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { PrivacyScreen } from "./src/Screens/Privacy";
import { TermsScreen } from "./src/Screens/Terms";

const Stack = createNativeStackNavigator();

const linking = {
  prefixes: ["/"],
};

export default function App() {
  const [loaded] = useFonts({
    HKNovaMedium: require("./assets/fonts/hk-nova/HKNova-Medium.ttf"),
  });
  if (!loaded) {
    console.log("font loading failed");
    return <View />;
  }
  return (
    <SafeAreaProvider>
      <ThemeProvider theme={theme}>
        <NavigationContainer linking={linking}>
          <Stack.Navigator
            initialRouteName="Home"
            screenOptions={{
              header: ({ navigation, route, options, back }) => {
                const companyName = "Steedit.";

                return <Header title={companyName} />;
              },
              animation: "slide_from_bottom",
            }}
          >
            <Stack.Screen name="Explore" component={ExploreScreen} />
            <Stack.Screen name="Home" component={HomeScreen} />
            <Stack.Screen name="Privacy" component={PrivacyScreen} />
            <Stack.Screen name="Terms" component={TermsScreen} />
          </Stack.Navigator>
        </NavigationContainer>
      </ThemeProvider>
    </SafeAreaProvider>
  );
}
