import { Hero } from "../../Components/Hero";
import { Layout } from "../../Components/Layout";
import styled from "styled-components/native";
import { TextWrapper } from "../../Atoms/Text";
import { useEffect, useRef, useState } from "react";
import { getDocs } from "firebase/firestore/lite";
import {
  categoriesData,
  placesQueryWithCity,
  placessData,
} from "../../Services/firebase";
import { FlatList, FlatListProps, TouchableHighlight } from "react-native";
import { useRoute } from "@react-navigation/native";
import { Pagination } from "./Pagination";

const AddressView = styled.View`
  position: absolute;
  background-color: #0009;
  padding: 2%;
  border-radius: 20px;
  bottom: 20px;
  left: 40px;
  right: 40px;
`;

const DeliverToText = styled(TextWrapper)`
  font-size: 24px;
  color: white;
`;

const AddressText = styled(TextWrapper)`
  font-size: 32px;
  font-weight: 700;
  color: white;
`;

const CategoryView = styled.View`
  min-height: 400px;
  padding: 40px;
`;

const ItemText = styled(TextWrapper)``;
const View = styled.View``;
const Text = styled(TextWrapper)``;
const Image = styled.Image``;
const ImageB = styled.ImageBackground``;

const RestaurentView = (props: any) => {
  const { place } = props;

  return (
    <ImageB
      source={{ uri: place.banner }}
      style={{
        position: "relative",
        borderRadius: 15,
        overflow: "hidden",
        height: 200,
        width: 250,
        margin: 8,
      }}
      resizeMode="cover"
    >
      <View
        style={{
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0,
          background: "linear-gradient(180deg, transparent, black)",
          zIndex: 10,
          paddingHorizontal: 16,
          paddingVertical: 8,
        }}
      >
        <View style={{ gap: 8, alignItems: "center", flexDirection: "row" }}>
          <Image
            source={{ uri: place.logo }}
            style={{ height: 24, width: 24, borderRadius: 12 }}
            resizeMode="cover"
          />

          <View>
            <Text style={{ fontSize: 14, color: "white", fontWeight: 700 }}>
              {place.name}
            </Text>
            <Text style={{ fontSize: 13, color: "#fffa" }}>{"0.4 mi"}</Text>
          </View>
        </View>
      </View>
    </ImageB>
  );
};

const Category = ({
  name,
  icon,
  id,
  setActiveCategory,
}: {
  name: string;
  icon: string;
  id: string;
  setActiveCategory: (id: string) => void;
}) => {
  return (
    <TouchableHighlight
      style={{
        margin: 8,
        overflow: "hidden",
        width: 160,
      }}
      onPress={() => setActiveCategory(id)}
    >
      <View
        style={{
          borderWidth: 1,
          borderColor: "lightgray",
          alignItems: "flex-end",
          position: "relative",
          flexDirection: "row",
          height: 56,
          overflow: "hidden",
        }}
      >
        <Text style={{ paddingLeft: 8, paddingBottom: 8 }}>{name}</Text>
        <Image
          source={{ uri: icon }}
          style={{
            height: 100,
            width: 100,
            borderRadius: 64,
            position: "absolute",
            bottom: -24,
            right: -24,
          }}
        />
      </View>
    </TouchableHighlight>
  );
};

interface CategoryItem {
  name: string;
  id: string;
  icon: string;
  is_icon_category: boolean;
  is_menu_category: boolean;
  is_more_category: boolean;
}

const ListHeader = (props: any) => {
  return (
    <View style={{ marginVertical: 12, marginHorizontal: 8 }}>
      <Text style={{ color: "black", fontWeight: 800, fontSize: 24 }}>
        {props.children}
      </Text>
    </View>
  );
};

export const ExploreScreen = () => {
  const [places, setPlaces] = useState([]);
  const [categories, setCategories] = useState<CategoryItem[]>([]);
  const [category, setCategory] = useState<String | null>(null);
  let categorySlider = useRef(null);
  const [categoryPage, setCategoryPage] = useState(1);
  let exploreSlider = useRef(null);
  const [explorePage, setExplorePage] = useState(1);
  const { params } = useRoute();

  useEffect(() => {
    const dd = async () => {
      const snapshot = await getDocs(
        // placessData
        placesQueryWithCity(params?.location?.split(", "))
      );
      const places = snapshot.docs.map((d) => ({ ...d.data() }));
      setPlaces(places);

      const snapshot2 = await getDocs(categoriesData);
      const categories = snapshot2.docs.map((d) => ({ ...d.data() }));
      setCategories(categories as CategoryItem[]);
    };
    dd();
  }, []);

  const filteredCategories = categories.filter((ct: any) =>
    places
      .map((p: any) => p.place_cats)
      .flat()
      .includes(ct.id)
  );

  const filteredPlaces = category
    ? places.filter(
        (pc: any) =>
          pc.place_cats.filter((f: string) => f === category).length > 0
      )
    : places;

  let newFilterData = [...filteredCategories];

  return (
    <Layout>
      <Hero height="400px" image={require("../../images/food_explore.png")}>
        <AddressView>
          <DeliverToText>Delivering to</DeliverToText>
          <AddressText>{params?.location}</AddressText>
        </AddressView>
      </Hero>
      <View
        style={{
          marginHorizontal: "10%",
          marginVertical: "5%",
        }}
      >
        <View>
          <ListHeader>Categories</ListHeader>
          <FlatList
            ref={(r) => (categorySlider.current = r)}
            horizontal
            pagingEnabled
            data={newFilterData}
            renderItem={({ item: category }) => (
              <Category
                key={category.name}
                name={category.name}
                icon={category.icon}
                setActiveCategory={(id) =>
                  setCategory((i) => (i == id ? null : id))
                }
                id={category.id}
              />
            )}
            keyExtractor={(item) => item.id}
          />
          <Pagination
            limit={6}
            onLeft={() => {
              if (categoryPage > 0) {
                categorySlider.current?.scrollToIndex({
                  animated: true,
                  index: (categoryPage - 1) * 6,
                });
                setCategoryPage((p) => p + 1);
              }
            }}
            onRight={(i: number) => {
              let pp = Math.ceil(filteredPlaces.length / 16);
              if (explorePage < pp) {
                categorySlider.current?.scrollToIndex({
                  animated: true,
                  index: (categoryPage + 1) * 6,
                });
                setCategoryPage((p) => p - 1);
              }
            }}
            total={newFilterData.length}
            active={categoryPage}
          />
        </View>
        <View>
          <FlatList
            numColumns={4}
            ref={(r) => (exploreSlider.current = r)}
            contentContainerStyle={{ marginTop: 100, minHeight: 400 }}
            ListHeaderComponent={() => <ListHeader>Explore</ListHeader>}
            data={filteredPlaces}
            keyExtractor={(item: any) => item.id}
            renderItem={({ item }) => <RestaurentView place={item} />}
          />
          <Pagination
            limit={16}
            total={filteredPlaces.length}
            active={explorePage}
            onLeft={() => {
              if (explorePage > 1) {
                exploreSlider.current?.scrollToIndex({
                  animated: true,
                  index: (explorePage - 1) * 16,
                });
                setCategoryPage((p) => p - 1);
              }
            }}
            onRight={(e) => {
              let pp = Math.ceil(filteredPlaces.length / 16);
              if (explorePage < pp) {
                exploreSlider.current?.scrollToIndex({
                  animated: true,
                  index: (explorePage + 1) * 16,
                });
                setExplorePage((p) => p + 1);
              }
            }}
          />
        </View>
      </View>
    </Layout>
  );
};
