import { TouchableOpacity } from "react-native";
import styled from "styled-components/native";
import { TextWrapper } from "./Text";

export const ButtonWrapper = styled.View`
  background-color: ${(props: any) => props.theme.appColor};
  border: 2px solid ${(props: any) => props.theme.appColor};
  padding: 8px;
  width: 120px;
  align-items: center;
  border-radius: 4px;
`;

const ButtonBorderWrapper = styled.View`
  border: 2px solid ${(props: any) => props.theme.appColor};
  padding: 8px;
  width: 120px;
  align-items: center;
  border-radius: 4px;
`;

const ButtonText: any = styled(TextWrapper)`
  color: ${(props: any) => (props.color ? props.theme.appColor : "white")};
  font-weight: 600;
  letter-spacing: 1.2px;
`;

const IconButtonWrapper = styled.View`
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  background-color: ${(props: any) => props.theme.appColor};
`;

const IconImage = styled.Image`
  width: 22px;
  height: 22px;
`;

const Touch = styled.TouchableOpacity``;
const Image = styled.Image`
  height: 64px;
  width: 150px;
`;

export const Button = (props: any) => {
  if (props.varient == "border") {
    return (
      <Touch activeOpacity={0.7} onPress={props.onClick}>
        <ButtonBorderWrapper>
          <ButtonText color={true}>{props.title}</ButtonText>
        </ButtonBorderWrapper>
      </Touch>
    );
  } else if (props.varient == "icon") {
    return (
      <Touch activeOpacity={0.7} onPress={props.onClick}>
        <IconButtonWrapper>
          <IconImage source={props.icon} resizeMode="contain" />
        </IconButtonWrapper>
      </Touch>
    );
  } else if (props.varient == "image") {
    return (
      <Touch activeOpacity={0.7} onPress={props.onClick}>
        <Image source={props.image} resizeMode="contain" />
      </Touch>
    );
  }
  return (
    <Touch activeOpacity={0.7} onPress={props.onClick}>
      <ButtonWrapper>
        <ButtonText>{props.title}</ButtonText>
      </ButtonWrapper>
    </Touch>
  );
};
